import { render, staticRenderFns } from "./SearchTrigger.vue?vue&type=template&id=1c215d1a&"
import script from "./SearchTrigger.ts?vue&type=script&lang=ts&"
export * from "./SearchTrigger.ts?vue&type=script&lang=ts&"
import style0 from "./SearchTrigger.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Ico: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Ico/Ico.vue').default})
